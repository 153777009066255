import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end","margin-bottom":"15px"}},[_c(VBtn,{staticStyle:{"margin-right":"15px"},attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.addDefaultGroup(_vm.content)}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v("Add new radio group")],1),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v("Open style settings")])],1),_c('div',_vm._l((_vm.content.content),function(radioGroup){return _c('div',{key:radioGroup.id,staticStyle:{"border":"1px solid rgb(238, 238, 238)","border-radius":"5px","margin":"15px 0"}},[_c('div',{staticStyle:{"padding":"15px","position":"relative"}},[_c('label',{staticClass:"label"},[_vm._v("Radio group title")]),_c(VTextField,{staticStyle:{"margin-top":"15px","max-width":"300px"},attrs:{"outlined":"","solo":"","hide-details":"","flat":"","dense":""},model:{value:(radioGroup.title),callback:function ($$v) {_vm.$set(radioGroup, "title", $$v)},expression:"radioGroup.title"}}),_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"position":"absolute","top":"10px","right":"10px","cursor":"pointer"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,true)},[_c(VList,[_c(VListItem,{staticStyle:{"cursor":"pointer"}},[_c(VListItemTitle,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addStationToGroupConfirm(radioGroup.id)}}},[_vm._v("Add radio")])],1),_c(VListItem,[_c(VListItemTitle,{staticStyle:{"cursor":"pointer","color":"red"},on:{"click":function($event){return _vm.openDeleteGroup(radioGroup.id)}}},[_vm._v("Delete")])],1)],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":radioGroup.stations,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticStyle:{"padding":"10px 0"}},[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"5px"}},[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":item.imageSrc?item.imageSrc:require('@/assets/none.png'),"alt":""}})]),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_c(VBtn,{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","outlined":"","small":"","icon":""},on:{"click":function($event){return _vm.isRadioPlaying(item)}}},[(!item.playing)?_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-play ")]):_vm._e(),(item.playing)?_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-pause ")]):_vm._e()],1)],1),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_c(VChip,{staticStyle:{"cursor":"pointer"},attrs:{"color":item.is_active ? 'green' : 'red',"text-color":"white"},on:{"click":function($event){return _vm.changeActive(radioGroup.id,item)}}},[_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")])],1),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectRadio(radioGroup.id,item)}}},[_c(VListItemTitle,[_vm._v("Edit radio")])],1),_c(VListItem,{staticStyle:{"cursor":"pointer","color":"red"}},[_c(VListItemTitle,{on:{"click":function($event){return _vm.deleteStationFromGroupOpen(radioGroup.id,item.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0):_vm._e(),(!items.length)?_c('tbody',[_c('tr',[_c('td',{staticStyle:{"text-align":"center","padding":"15px","font-weight":"normal"},attrs:{"colspan":"6"}},[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_vm._v(" You don't have radio ")])])])]):_vm._e()]}}],null,true)})],1)}),0),_c('PopupDelete',{ref:"PopupDeleteGroup",attrs:{"title":"Are you sure about delete group?"},on:{"confirm":_vm.deleteGroupConfirm}}),_c('PopupDelete',{ref:"PopupDeleteStation",attrs:{"title":"Are you sure about delete station?"},on:{"confirm":_vm.deleteStationConfirm}}),_c('PopupError',{ref:"PopupError",attrs:{"title":_vm.errorMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }