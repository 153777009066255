<template>
  <div>
    <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom:15px;">
      <v-btn small outlined color="primary" @click="addDefaultGroup(content)" style="margin-right: 15px;"><v-icon>mdi-plus</v-icon>Add new radio group</v-btn>
      <v-btn small outlined color="primary" >Open style settings</v-btn>
    </div>
    <div>
      <div v-for="radioGroup in content.content" :key="radioGroup.id" style="border:1px solid rgb(238, 238, 238);border-radius: 5px;margin:15px 0;">
        <div style="padding:15px;position: relative;">
          <label class="label">Radio group title</label>
          <v-text-field
              style="margin-top: 15px;max-width:300px;"
              outlined
              solo
              hide-details
              flat
              v-model="radioGroup.title"
              dense
          ></v-text-field>
          <v-menu offset-y open-on-hover bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    style="position: absolute;top:10px;right:10px;cursor:pointer;"
                    color="primary"
                    v-bind="attrs"
                    v-on="on">mdi-cog</v-icon>
            </template>
            <v-list>
              <v-list-item style="cursor:pointer;">
                <v-list-item-title style="cursor:pointer;" @click="addStationToGroupConfirm(radioGroup.id)">Add radio</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title style="cursor:pointer;color:red;" @click="openDeleteGroup(radioGroup.id)">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-data-table
          :headers="headers"
          :items="radioGroup.stations"
          hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
          <tr v-for="item in items" :key="item.id" style="padding:10px 0;">
              <td class="d-block d-sm-table-cell" style="text-align: center;padding:5px;">
                <img :src="item.imageSrc?item.imageSrc:require('@/assets/none.png')" alt="" style="width:50px;height:50px;">
              </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              {{item.title}}
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <v-btn
                  color="primary"
                  outlined
                  small
                  icon
                  style="cursor: pointer"
                  @click="isRadioPlaying(item)"
              >
                <v-icon dark v-if="!item.playing">
                  mdi-play
                </v-icon>
                <v-icon dark v-if="item.playing">
                  mdi-pause
                </v-icon>
              </v-btn>
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <v-chip
                  :color="item.is_active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click="changeActive(radioGroup.id,item)"
              >
                {{ item.is_active ? "Active" : "Inactive" }}
              </v-chip>
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                      style="cursor: pointer;" @click="selectRadio(radioGroup.id,item)"
                  >
                    <v-list-item-title
                    >Edit radio</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                      style="cursor: pointer;color:red;"
                  >
                    <v-list-item-title @click="deleteStationFromGroupOpen(radioGroup.id,item.id)"
                    >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
          <tbody v-if="!items.length">
          <tr>
            <td colspan="6" style="text-align: center;padding:15px;font-weight: normal;">
              <div style="margin-bottom: 15px;">
                You don't have radio
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      </div>
    </div>
    <PopupDelete ref="PopupDeleteGroup" @confirm="deleteGroupConfirm" :title="`Are you sure about delete group?`"/>
    <PopupDelete ref="PopupDeleteStation" @confirm="deleteStationConfirm" :title="`Are you sure about delete station?`"/>
    <PopupError ref="PopupError" :title="errorMessage"/>
  </div>
</template>

<script>
import {mapMutations,mapState} from "vuex";
import {Howl, Howler} from "howler";

export default {
  name: "RadioList",
  components: {
    PopupDelete:() => import("@/components/Popup/PopupDelete"),
    PopupError:() => import("@/components/Popup/PopupError"),
  },
  props:['item','content'],
  data(){
    return{
      deleteGroupID:null,
      deleteStationID:null,
      playingGroupID:null,
      errorMessage:"",
      headers:[
          {
            text: 'Image',
            value: 'imageSrc',
            align: 'center',
            sortable:false,
          },
          {
            text: 'Name',
            value: 'title',
            align: 'center',
            sortable:false,
          },
          {
            text: 'Test',
            value: 'src',
            align: 'center',
            sortable:false,
          },
          {
            text: 'Active',
            value: 'is_active',
            align: 'center',
            sortable:false,
          },
          {
            text: 'Options',
            align: 'center',
            sortable:false,
          }
      ],
      radioStarted:false,
    }
  },
  computed:{
    ...mapState('radio',['volume','soundID','sound','selectedRadio']),
  },
  methods:{
    ...mapMutations('radio',['addDefaultGroup','setSoundID','deleteGroup','addStationToGroup','setSelectedStation','deleteStationFromGroup','editStationFromGroup','setSound']),
    addStationToGroupConfirm(id){
      const payload = {
        content:this.content.content,
        stationGroupID:id
      }
      this.addStationToGroup(payload);
    },
    playAudio() {
      this.setSoundID(this.sound.play());
    },
    startRadio(station) {
      this.radioStarted = true;
      station.playing = true;
      this.setSound(new Howl({
        src: station.src,
        html5: true,
        volume: this.volume,
      }));
      Howler.masterGain.gain.value = this.volume;
      if (this.radioMuted) {
        this.muteRadioOnStart();
      }

    },
    muteRadioOnStart() {
      this.sound.fade(this.volume, 0.0, 0);
    },
    isRadioPlaying(station) {
      if(!station.src){
        return;
      }
      if (station.playing === false && this.radioStarted === false) {
        this.startRadio(station);
        this.playAudio();
        this.radioPaused = false;
      }
      else if (
          station.playing === false &&
          this.radioStarted === true
      ) {
        this.stopRadio(this.selectedRadio);
        this.startRadio(station);
        this.playAudio();
      } else {
        this.stopRadio(this.selectedRadio);
        this.radioPaused = true;
      }
      const payload = {
        groupID:station.group_id,
        station:station
      }
      this.setSelectedStation(payload);
    },
    stopRadio(station) {
      station.playing = false;
      this.radioStarted = false;
      this.sound.unload();
    },
    openDeleteGroup(id){
      this.deleteGroupID = id;
      this.$refs.PopupDeleteGroup.open();
    },
    changeActive(groupID,station){
      station.is_active = !station.is_active;
      const payload = {
        groupID:groupID,
        station:station,
        content:this.content.content
      };
      this.editStationFromGroup(payload);
    },
    selectRadio(groupID,station){
      const payload = {
        groupID:groupID,
        station:station
      };
      this.setSelectedStation(payload)
      this.$emit('openEdit');
    },
    async openLink(link){
      if(link){
          window.open(link,'_blank')
      }else{
        this.errorMessage = "This radio don't have a website =("
        this.$refs.PopupError.open();
      }
    },
    deleteStationFromGroupOpen(groupID,stationID){
      this.deleteGroupID = groupID;
      this.deleteStationID = stationID;
      this.$refs.PopupDeleteStation.open();
    },
    deleteStationConfirm(){
      const payload = {
        groupID:this.deleteGroupID,
        stationID:this.deleteStationID,
        content:this.content.content
      }
      this.deleteStationFromGroup(payload);
    },
    deleteGroupConfirm(){
      const payload = {
        stationGroupID:this.deleteGroupID,
        content:this.content.content
      }
      this.deleteGroup(payload)
    }
  }
}
</script>

<style scoped>

</style>